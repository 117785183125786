import React, { useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { Button, Box, Stack, Menu, MenuItem } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SortIcon from '@mui/icons-material/Sort';  
import {
  filterA,
  filterB,
  filterC,
  filterD,
} from "../constant/CardFilterItems";
import FilterMenuContent from "./FilterMenuContent";

const GroupFilter = (props) => {
  const history = useHistory();
  const { items, type, cardFilter, setCardFilter, rudeCardFilter, setRudeCardFilter, setSortFilter } = props;
  const { occasion, genres } = useParams();
  const [sortType, setSortType] = useState(1);
  const [filter1, setFilter1] = useState('')
  const [filter2, setFilter2] = useState('')
  const [filter3, setFilter3] = useState('')

  const handleClick = (occasion) => {
    if (type === "themes") {
      history.push(`/product/${occasion}/1`);
    } else if (type === "gifts") {
      history.push(`/gift/${occasion}/1`);
    } else if (type === "addcard") {
      history.push(`/addcard/${occasion}/1`);
    }
  };

  const handleAll = () => {
    if (type === "themes") {
      history.push(`/products/page/1`);
    } else if (type === "gifts") {
      history.push(`/gifts/page/1`);
    } else if (type === "addcard") {
      history.push("/addcards/page/1");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const openSort = Boolean(anchorElSort);
  
  const menuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuOpenSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  const menuCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleSortSelection = (id) => {
    setSortFilter(id);
    setSortType(id);
    menuCloseSort();
  } 

  return (
    <Box className="filterCardPanel">
      <Stack direction="row" spacing={1}>
        {type === "themes" || type === "addcard" ? (
          <>
            <Button
              variant="outlined"
              startIcon={<TuneIcon />}
              sx={{ borderRadius: "10px", paddingX: "20px" }}
              onClick={menuOpen}
            >
              <span>Filters</span>
            </Button>
          </>
        ) : null}

        <Button
          variant="outlined"
          startIcon={<SortIcon />}
          sx={{ borderRadius: "10px", paddingX: "20px" }}
          onClick={menuOpenSort}
        >
          <span>Sort</span>
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={menuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            dense: true,
          }}
        >
          <FilterMenuContent
            filterA={filterA}
            // filterB={filterB}
            filterC={filterC}
            filterD={filterD}
            // cardFilter={cardFilter}
            setCardFilter={setCardFilter}
            filter1={filter1}
            setFilter1={setFilter1}
            filter2={filter2}
            setFilter2={setFilter2}
            filter3={filter3}
            setFilter3={setFilter3}
            rudeCardFilter={rudeCardFilter}
            setRudeCardFilter={setRudeCardFilter}
            menuClose={menuClose}
          />
        </Menu>

        <Menu
          id="sort-menu"
          anchorEl={anchorElSort}
          open={openSort}
          onClose={menuCloseSort}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            dense: true,
          }}
        >
            <MenuItem selected={sortType === 1} onClick={() => handleSortSelection(1)}>Newest First</MenuItem>  
            <MenuItem selected={sortType === 0} onClick={() => handleSortSelection(0)}>Oldest First</MenuItem>  
            <MenuItem selected={sortType === 2} onClick={() => handleSortSelection(2)}>Alphabetically</MenuItem>  
        </Menu>

        <Button
          variant={occasion ? "outlined" : genres ? "outlined" : "contained"}
          sx={{ borderRadius: "10px" }}
          onClick={() => handleAll()}
        >
          <span>All</span>
        </Button>
        {items.map((category, index) => (
          <Button
            key={index}
            variant={category.name === occasion ? "contained" : "outlined"}
            sx={{
              lineHeight: "1",
              borderRadius: "10px",
            }}
            onClick={() => handleClick(category.name)}
          >
            <span>{category.name}</span>
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default GroupFilter;
