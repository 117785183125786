import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Container,
  IconButton,
  Typography,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Theme from "../components/themes/Theme";
import GroupFilter from "../components/home/GroupFilter";

import { listProducts } from "../store/actions/productActions";
import { listCategories } from "../store/actions/categoryActions";
import { listFavourites } from "../store/actions/favouriteActions";
import { Pagination, PaginationItem, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

export default function ThemeScreen(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 576px");
  const isApple = window.innerWidth <= 576;

  const [cardsNumber, setCardsNumber] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [cardFilter, setCardFilter] = useState([]);
  const [rudeCardFilter, setRudeCardFilter] = useState(true);
  const [shuffledProducts, setShuffledProducts] = useState([]);
  const [sortFilter, setSortFilter] = useState(1);

  const keyword = props.match.params.keyword;

  const { categories } = useSelector((state) => state.categoryList);
  const items = categories.filter((item) => item.card === true);

  // Favourite cards
  const { userInfo } = useSelector((state) => state.userLogin);
  const [favouriteList, setFavouriteList] = useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = useState(0);  
  const [showProducts, setShowProducts] = useState([]);

  const { success: createSuccess } = useSelector(
    (state) => state.favouriteCreate
  );
  const { success: deleteSuccess } = useSelector(
    (state) => state.favouriteDelete
  );
  useEffect(() => {
    if (userInfo) {
      dispatch(listFavourites(userInfo._id)).then((res) => {
        const favourites = res.data.favourites;
        setFavouriteList(favourites.map((item) => item.product));
      });
    }
  }, [dispatch, userInfo, createSuccess, deleteSuccess]);

  const { occasion } = props;

  useEffect(() => {
    dispatch(listProducts(keyword, page, rudeCardFilter, occasion, cardFilter, sortFilter))
      .then((res) => {
        setCardsNumber(res.data.products.length);
        setTotalPage(res.data.count)
        // }
        setAllProducts(res.data.products);
        setShuffledProducts(res.data.products);
      })
      .catch((error) => console.log(error));
  }, [dispatch, occasion, keyword, page, rudeCardFilter, cardFilter, sortFilter]);
  let filteredProducts = shuffledProducts;

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const backSearch = () => {
    history.goBack();
  };

  function onReturn(e) {
    e.preventDefault();
    history.push("/");
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo({  
      top: 0,  
      behavior: 'smooth' // Optional: for smooth scrolling  
    });  
  };
  const CustomPagination = ({ count, page, onPageChange }) => {
    const renderPaginationItems = () => {
      const items = [];
      
      // Always show the first page
      items.push(1);
  
      // Show the current page and surrounding pages
      if (page > 2) {
        items.push(page - 1);
      }
  
      if (page > 1 && page < count) {
        items.push(page);
      }
  
      if (page < count - 1) {
        items.push(page + 1);
      }
  
      // Always show the last page if not already shown
      if (count > 1) {
        items.push(count);
      }
  
      const uniqueItems = [...new Set(items)];
      
      // If we have more than 4 items, we need to adjust
      if (uniqueItems.length > 4) {
        const finalItems = [];
        finalItems.push(uniqueItems[0]); // Always include the first page

        // Add ellipsis if there's a gap
        if (uniqueItems[1] > 2) {
          finalItems.push('...');
        }

        // Add the last three pages
        finalItems.push(uniqueItems[uniqueItems.length - 2]);
        finalItems.push(uniqueItems[uniqueItems.length - 1]);

        return finalItems;
      }

      return uniqueItems;
    };

    return (
      <Pagination
        count={count}
        page={page}
        onChange={(event, value) => onPageChange(value)}
        className="custom-pagination"
      >
        {renderPaginationItems().map((item) => (
          <PaginationItem
            key={item}
            onClick={() => item !== '...' && onPageChange(item)}
            disabled={item === '...'}
            label={item}
            page={item === '...' ? undefined : item}
          />
        ))}
      </Pagination>
    );
  }

  return (
    <Container className="theme-container">
      <Stack direction="column" spacing={1}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ marginBottom: "10px", marginTop: "10px" }}
        >
          {keyword ? (
            <>
              <IconButton aria-label="goback" onClick={backSearch}>
                <ArrowBackIcon fontSize="small" />
              </IconButton>
              <Typography variant="h5">
                Search results with "{keyword}" ({cardsNumber})
              </Typography>
            </>
          ) : (
            <>
              <IconButton aria-label="goback" onClick={onReturn}>
                <ArrowBackIcon />
              </IconButton>
              {occasion ? (
                occasion !== "undefined" && (
                  <Typography variant="h5">
                    <strong>
                      {occasion} Cards ({totalPage})
                    </strong>
                  </Typography>
                )
              ) : (
                <Typography variant="h5">
                  <strong>All Cards ({totalPage})</strong>
                </Typography>
              )}
            </>
          )}
        </Stack>

        {keyword ? null : (
          <GroupFilter
            items={items}
            type="themes"
            cardFilter={cardFilter}
            setCardFilter={setCardFilter}
            rudeCardFilter={rudeCardFilter}
            setRudeCardFilter={setRudeCardFilter}
            setSortFilter={setSortFilter}
          />
        )}

<Box className="row">
  {filteredProducts.map((product, index) => (
    <div className="theme-Lazy" key={index}>
      <Theme product={product} favouriteList={favouriteList} />
    </div>
  ))}
</Box>

        <Box display="flex" justifyContent="center" sx={{  marginTop: "40px !important", flexWrap: 'nowrap' }}>
          <CustomPagination count={Math.ceil(totalPage/40)} page={page} onPageChange={handlePageChange} />
        </Box>
      </Stack>
    </Container>
  );
}
