import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Button,
  Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from '@mui/material/styles';
import colors from "../constant/Colors";
import CloseIcon from '@mui/icons-material/Close';
import {
  createFavourite,
  deleteFavourite,
} from "../../store/actions/favouriteActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Theme({ product, giftId, favouriteList }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { cartItems } = useSelector((state) => state.cart);
  const greetingCards = cartItems.filter((item) => item.extra === false);

  const [open, setOpen] = useState(false);
  const [isWish, setIsWish] = useState(false);

  useEffect(() => {
    setIsWish(favouriteList?.includes(product._id));
  }, [favouriteList]);

  const handleClose = () => {
    setOpen(false);
  };



  const handleClick = (id) => {
    if (greetingCards.length > 0) {
      setOpen(true);
    } else {
      if (giftId) {
        history.push(`/cardinfo/${id}/${giftId}`);
      } else {
        history.push(`/cardinfo/${id}`);
      }
    }
  };

  const favouriteHandler = () => {
    if (userInfo) {
      if (!isWish) {
        dispatch(createFavourite(userInfo._id, product));
      } else {
        dispatch(deleteFavourite(userInfo._id, product._id));
      }
      setIsWish(!isWish);
    } else {
      history.push("/login");
    }
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [modalImageUrl, setModalImageUrl] = React.useState(false);

  const handleModalClickOpen = (value) => {
    setModalImageUrl(value);
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Card className="theme-Card">
        <div style={{ position: "relative" }}>
          <img
            src={product.imageUrl}
            alt="card-theme"
            className="img-thumbnail"
            onClick={() => handleModalClickOpen(product.imageUrl)}
          />
          <IconButton
            sx={{
              position: "absolute",
              bottom: 10,
              right: 10,
              backgroundColor: colors.lightgrey,
              opacity: 0.9,
              boxShadow: 2,
              "&:hover": {
                backgroundColor: colors.white,
              },
            }}
            onClick={() => favouriteHandler(product._id)}
          >
            {isWish ? (
              <FavoriteIcon color="error" fontSize="small" />
            ) : (
              <FavoriteBorderIcon fontSize="small" />
            )}
          </IconButton>
        </div>
      </Card>

      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Card Preview
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{ overflow: 'hidden' }}>
          <img
            src={modalImageUrl}
            alt="card-theme"
            className="img-thumbnail"
            style={{height: '500px',
              width: 'auto',
              objectFit: "cover"}}
          />
        </DialogContent>
        <DialogActions style={{ width: '100%' }}>
          <Button variant="contained" fullWidth onClick={() => handleClick(product._id)} sx={{fontSize: "20px",fontWeight: 'bold'}}>
            Choose this card
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: colors.backerror, color: colors.error }}
        >
          <InfoOutlinedIcon /> One Card Per Order
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.backerror }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "justify", color: colors.error }}
          >
            You can only have one greeting card in your order. Please finish or
            remove the current card from your basket before adding a new one.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
